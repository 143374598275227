import React from 'react'
import Modal from '@berlitz/modal'
import { FormattedMessage } from 'react-intl'
import Button from '@components/Button'
import { Header, Title, Subtext, UnitList, UnitCard, UnitInfo, UnitTitle, UnitDescription } from './style'

class LiveCoachingSessionModal extends React.Component<{
  open: boolean
  onClose: () => void
  unitCount: number
  units: any[]
  bookingHandler: (unitId: string) => void
}> {
  render() {
    const { open, onClose, unitCount, units, bookingHandler } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <Header>
          <Title>
            You have {unitCount} <FormattedMessage id="live coaching sessions unlocked" />
          </Title>
          <Subtext>
            <FormattedMessage id="Select and book a session below " />
          </Subtext>
        </Header>
        <UnitList>
          {units.map((unit, id) => (
            <UnitCard key={id}>
              <UnitInfo>
                <UnitTitle>{unit.label}</UnitTitle>
                <UnitDescription>{unit.desc}</UnitDescription>
              </UnitInfo>
              <Button color="success" narrow onClick={() => bookingHandler(unit.id)}>
                <FormattedMessage id="Book" />
              </Button>
            </UnitCard>
          ))}
        </UnitList>
      </Modal>
    )
  }
}

export default LiveCoachingSessionModal
