import PortalStudentProfile from '@classes/StudentProfile'
import Select from '@components/Select'
import useCTPermission from '@hooks/useCTPermission'
import { StoreProps } from '@interfaces/StoreState'
import { ProgramSelectorProp } from '@layouts/Dashboard/HeroBlock/interface'
import { ProgramsWrapper } from '@layouts/Dashboard/HeroBlock/style'
import { updateDashboardActiveMaterial } from '@redux/actions/dashboard'
import { CURRICULUM_TEST_STATUS, REG_STATUS } from '@utils/constants'
import { getQueryParams } from '@utils/helpers'
import { getStoredMaterials } from '@utils/helpers/material'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

const RegStatusInOrder = [
  REG_STATUS.PENDING_START,
  REG_STATUS.ACTIVE,
  REG_STATUS.COMPLETED,
  REG_STATUS.EXPIRED,
  REG_STATUS.PENDING_START,
  REG_STATUS.CANCELLED,
  REG_STATUS.DRAFT,
  REG_STATUS.IN_PROGRESS,
  REG_STATUS.PAUSED,
  REG_STATUS.EXPIRED,
]

const ProgramSelector: React.FC<ProgramSelectorProp> = ({
  hide,
  defaultProgram,
  bgOn,
  StudentProfile,
  label = '',
  showCT,
  LearningPaths,
  setShowSelector = () => {},
}) => {
  const router = useRouter()
  const curriculumTestId = getQueryParams('dd')
  const { activeMaterial, Profile, userPermissions } = useSelector(
    ({ userProfile, dashboard, permissions }: StoreProps) => ({
      Profile: StudentProfile || new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo),
      activeMaterial: dashboard.activeMaterial,
      userPermissions: permissions.permissions,
    })
  )
  const ISCFlag = userPermissions['flag::industrySpecificContent']?.show
  const { getPermissionByProgramType } = useCTPermission()

  let materialsDD = showCT ? Profile.LPMaterialDropDown : Profile.MaterialDropDown
  let suboptionCounter = 0
  materialsDD = materialsDD
    .map((material) => {
      return {
        ...material,
        sortValue: RegStatusInOrder.indexOf(material.regStatus ?? ''),
        suboptions: material.suboptions
          .filter(({ material: mat }) => {
            if (mat?.IsTest) {
              const ctFlag = getPermissionByProgramType(mat?.ProgramType)
              const isCTAllowed =
                (mat?.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED || CURRICULUM_TEST_STATUS.COMPLETED) && ctFlag
              return isCTAllowed
            }
            return true
          })
          .map((subopt) => ({
            ...subopt,
            originalValue: subopt.value, // Store original value
            value: `${++suboptionCounter}-${subopt.value}`, // Create unique value
          })),
      }
    })
    .filter((material) => material.material)

  const dispatch = useDispatch()
  const opt = localStorage.getItem('activeOPT')

  const [selectedProgram, setSelectedProgram] = useState(() => {
    const defaultMaterial = showCT
      ? opt || curriculumTestId || defaultProgram || activeMaterial || materialsDD?.[0]?.value
      : opt ||
        curriculumTestId ||
        defaultProgram ||
        materialsDD.find((i) => i.value === activeMaterial)?.value ||
        materialsDD?.[0]?.value

    const material = LearningPaths?.getLearningPathById(defaultMaterial)
    if (material?.IsCTMaterial) {
      const permission = getPermissionByProgramType(material?.Material?.ProgramType)
      if (permission) {
        return defaultMaterial
      }
      return material.MaterialCore?.LPID
    }
    return defaultMaterial
  })

  useEffect(() => {
    const activeMat = ISCFlag ? materialsDD.find(({ value }) => value === activeMaterial)?.value : activeMaterial
    if (!activeMat && materialsDD.length) {
      dispatch(updateDashboardActiveMaterial(selectedProgram))
    }
  }, [])

  useEffect(() => {
    if (curriculumTestId) {
      setSelectedProgram(curriculumTestId)
      dispatch(updateDashboardActiveMaterial(curriculumTestId))
      router.push(`/learning-path`)
    }
  }, [curriculumTestId])

  useEffect(() => {
    if (hide || (materialsDD.length < 2 && !materialsDD.find((dd) => dd.suboptions?.length > 1))) {
      setShowSelector(false)
    }
  }, [materialsDD])

  useEffect(() => {
    if (selectedProgram === localStorage.getItem('activeOPT')) {
      localStorage.removeItem('activeOPT')
    }
  }, [selectedProgram])

  if (hide || (materialsDD.length < 2 && !materialsDD.find((dd) => dd.suboptions?.length > 1))) {
    return null
  }

  // Helper function to get the label for a given value
  const getLabelForValue = (value: string) => {
    // Check suboptions
    for (const material of materialsDD) {
      const suboption = material.suboptions.find((sub) => {
        // Check both unique value and original value
        return sub.value === value || sub.originalValue === value
      })
      if (suboption) return suboption.label
    }
    return value
  }

  const getOriginalValue = (uniqueValue: string) => {
    for (const material of materialsDD) {
      const suboption = material.suboptions.find((sub) => sub.value === uniqueValue)
      if (suboption) {
        return suboption.originalValue
      }
    }
    return uniqueValue // Return as-is if not found in suboptions
  }

  const handleProgramChange = (materialId: string) => {
    const originalMaterialId = getOriginalValue(materialId)

    // Update localStorage
    const materials = getStoredMaterials()
    materials[Profile.SFId] = originalMaterialId
    localStorage.setItem('portal-selected-material', JSON.stringify(materials))

    setSelectedProgram(originalMaterialId)

    // Update Redux store
    dispatch(updateDashboardActiveMaterial(originalMaterialId))
  }

  return (
    <ProgramsWrapper bgOn={bgOn}>
      {!label && (
        <span>
          <FormattedMessage id="Select your course:" />
        </span>
      )}
      <div>
        <Select
          label={label}
          name="Program"
          value={getLabelForValue(selectedProgram)}
          onChange={handleProgramChange}
          options={materialsDD}
          disableMargin
          responsive
        />
      </div>
    </ProgramsWrapper>
  )
}

export default ProgramSelector
