import styled from 'styled-components'

export const Header = styled.div`
  margin-bottom: 0.5rem;
`

export const Title = styled.h2`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
`

export const Subtext = styled.p`
  font-size: 0.875rem;
  color: #777;
`

export const UnitList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  overflow-y: auto;
  max-height: 40vh; /* Enables scroll when units increase */
  scrollbar-width: thin;
  scrollbar-color: #e5e5e5 transparent;
`

export const UnitCard = styled.div`
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
`

export const UnitInfo = styled.div`
  flex-grow: 1;
`

export const UnitTitle = styled.h3`
  font-weight: 500;
  margin-bottom: 0.25rem;
`

export const UnitDescription = styled.p`
  font-size: 0.875rem;
  color: #666;
`
